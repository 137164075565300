import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { UrlObject } from 'url';
import { Button } from '@cbhq/cds-web/buttons/Button';
import { IconButton } from '@cbhq/cds-web/buttons/IconButton';
import { Box, Group, VStack } from '@cbhq/cds-web/layout';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
  TableRow,
} from '@cbhq/cds-web/tables';
import { Link as CDSLink, TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import { Product } from ':data-marketplace/types/Product';
import { QUERY_PARAM_PRODUCT_PAGE } from ':data-marketplace/utils/constants';
import {
  EventActionType,
  EventComponentType,
  EventUUID,
  sendEventCallback,
} from ':data-marketplace/utils/events';
import { NoWrapWhitespace } from ':data-marketplace/utils/hacks';
import { getCategoryUrl, getPackageUrl } from ':data-marketplace/utils/url';

export type Props = {
  products: Product[];
  itemCount: number;
  pageSize: number;
  currentPage: number;
};

const PAGINATION_DELTA = 3;

export const ProductsTable = memo(function ProductsTable({
  products,
  itemCount,
  pageSize,
  currentPage,
}: Props) {
  const intl = useIntl();
  const router = useRouter();

  const pageCount = Math.ceil(itemCount / pageSize);
  const previousPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage = currentPage !== pageCount ? currentPage + 1 : null;

  const paginationState = useMemo(() => {
    const startPage = currentPage > PAGINATION_DELTA ? currentPage - PAGINATION_DELTA : currentPage;
    const endPage =
      currentPage > pageCount - PAGINATION_DELTA ? currentPage : currentPage + PAGINATION_DELTA;
    const middlePageCount = endPage - startPage + 1;

    const isDisplayFirstPage = startPage !== 1;
    const isDisplayLastPage = endPage !== pageCount;

    const isDisplayFirstPageDots = startPage >= PAGINATION_DELTA;
    const isDisplayLastPageDots = endPage <= pageCount - PAGINATION_DELTA;

    const middlePages = new Array(middlePageCount).fill(null).map((_, index) => index + startPage);

    return {
      isDisplayFirstPage,
      isDisplayLastPage,
      isDisplayFirstPageDots,
      isDisplayLastPageDots,
      middlePages,
    };
  }, [currentPage, pageCount]);

  const isResultsEmpty = itemCount <= 0;
  const isPaginationShown = pageCount > 1;

  const getPageNumberUrl = useCallback(
    (pageNumber: number | null): string | UrlObject => {
      if (pageNumber === null) {
        return router.asPath;
      }

      return {
        href: router.asPath,
        pathname: router.pathname,
        query: { ...router.query, [QUERY_PARAM_PRODUCT_PAGE]: pageNumber.toString() },
      };
    },
    [router],
  );

  return (
    <>
      {isResultsEmpty && (
        <VStack flexBasis="100%" justifyContent="center" alignItems="center" spacing={2}>
          <TextTitle3 as="h3">No results</TextTitle3>
          <TextBody as="p" spacingBottom={2}>
            Try refining your search.
          </TextBody>
        </VStack>
      )}

      {!isResultsEmpty && (
        <Table bordered variant="ruled">
          <TableHeader>
            <TableRow backgroundColor="backgroundAlternate" color="foregroundMuted">
              <TableCell title="Category" className={NoWrapWhitespace} />
              <TableCell title="Package" className={NoWrapWhitespace} />
              <TableCell title="Product" className={NoWrapWhitespace} />
              <TableCell title="Asset" className={NoWrapWhitespace} />
              <TableCell title="Source" className={NoWrapWhitespace} />
              <TableCell title="Provider" className={NoWrapWhitespace} />
              <TableCell title="From Date" className={NoWrapWhitespace} />
            </TableRow>
          </TableHeader>
          <TableBody>
            {products.map((product) => {
              return (
                <TableRow key={product.id}>
                  <TableCell className={NoWrapWhitespace}>
                    <Link href={`/${getCategoryUrl(product.categoryId)}`} passHref legacyBehavior>
                      <CDSLink
                        variant="headline"
                        onClick={sendEventCallback(
                          EventUUID.products_category_change,
                          EventActionType.click,
                          EventComponentType.link,
                          {
                            context: product.categoryName,
                          },
                        )}
                      >
                        {product.categoryName}
                      </CDSLink>
                    </Link>
                  </TableCell>
                  <TableCell className={NoWrapWhitespace}>
                    <Link
                      href={`/${getPackageUrl(product.categoryId, product.packageId)}`}
                      passHref
                    >
                      <CDSLink
                        variant="headline"
                        onClick={sendEventCallback(
                          EventUUID.products_package_change,
                          EventActionType.click,
                          EventComponentType.link,
                          {
                            context: product.packageName,
                          },
                        )}
                      >
                        {product.packageName}
                      </CDSLink>
                    </Link>
                  </TableCell>
                  <TableCell title={product.productType} className={NoWrapWhitespace} />
                  <TableCell title={product.assetName} className={NoWrapWhitespace} />
                  <TableCell title={product.sourceName} className={NoWrapWhitespace} />
                  <TableCell title={product.providerName} className={NoWrapWhitespace} />
                  <TableCell
                    title={intl.formatDate(new Date(product.fromDateStr))}
                    className={NoWrapWhitespace}
                  />
                </TableRow>
              );
            })}
          </TableBody>

          {isPaginationShown && (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={7}>
                  <Group
                    spacingHorizontal={4}
                    gap={1}
                    direction="horizontal"
                    accessibilityLabel="Page links"
                    testID="tablePagination"
                  >
                    {/* Previous page */}
                    {Number.isInteger(previousPage) && (
                      <Box>
                        <Link href={getPageNumberUrl(previousPage)} passHref legacyBehavior>
                          <IconButton
                            name="caretLeft"
                            variant="secondary"
                            accessibilityLabel="Previous"
                          />
                        </Link>
                      </Box>
                    )}

                    {paginationState.isDisplayFirstPage && (
                      <Link href={getPageNumberUrl(1)} passHref legacyBehavior>
                        <Button compact variant={currentPage === 1 ? 'primary' : 'secondary'}>
                          1
                        </Button>
                      </Link>
                    )}

                    {paginationState.isDisplayFirstPageDots && (
                      <Button compact variant="secondary" disabled>
                        ...
                      </Button>
                    )}

                    {/* Pages */}
                    {paginationState.middlePages.map((pageNumber) => (
                      <Link
                        key={pageNumber}
                        href={getPageNumberUrl(pageNumber)}
                        passHref
                        legacyBehavior
                      >
                        <Button
                          key={pageNumber}
                          compact
                          variant={currentPage === pageNumber ? 'primary' : 'secondary'}
                        >
                          {pageNumber}
                        </Button>
                      </Link>
                    ))}

                    {paginationState.isDisplayLastPageDots && (
                      <Button compact variant="secondary" disabled>
                        ...
                      </Button>
                    )}

                    {paginationState.isDisplayLastPage && (
                      <Link href={getPageNumberUrl(pageCount)} passHref legacyBehavior>
                        <Button
                          compact
                          variant={currentPage === pageCount ? 'primary' : 'secondary'}
                        >
                          {pageCount}
                        </Button>
                      </Link>
                    )}

                    {/* Next page */}
                    {Number.isInteger(nextPage) && (
                      <Box>
                        <Link href={getPageNumberUrl(nextPage)} passHref legacyBehavior>
                          <IconButton
                            name="caretRight"
                            variant="secondary"
                            accessibilityLabel="Next"
                          />
                        </Link>
                      </Box>
                    )}
                  </Group>
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      )}
    </>
  );
});
