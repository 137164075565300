import { assets } from ':data-marketplace/data/assets';
import { products } from ':data-marketplace/data/products';
import { Asset } from ':data-marketplace/types/Asset';

export function getFilteredAssets(categoryId?: string, packageId?: string): Asset[] {
  let filteredProducts = products;

  // Filter any products that do not match the provided category ID
  if (categoryId) {
    filteredProducts = filteredProducts.filter((product) => product.categoryId === categoryId);
  }

  // Filter any products that do not match the provided package ID
  if (packageId) {
    filteredProducts = filteredProducts.filter((product) => product.packageId === packageId);
  }

  // Filter the assets by checking whether the asset exists in the filteredProducts
  const filteredAssets = assets.filter((asset) =>
    filteredProducts.some((product) => product.assetId === asset.id),
  );

  return filteredAssets;
}
