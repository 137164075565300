import uniqBy from 'lodash/uniqBy';

import { products } from ':data-marketplace/data/products';
import { Asset } from ':data-marketplace/types/Asset';

const assetPriorityList = [
  'btc',
  'eth',
  'sol',
  'avax',
  'shib',
  'ada',
  'ape',
  'usdt',
  'gala',
  'matic',
  'doge',
  'atom',
  'link',
  'ltc',
  'mana',
  'dot',
  'algo',
];

// Get a unique list of asset names
const unsortedAssets: Asset[] = uniqBy(
  products.map((product) => ({
    id: product.assetId,
    name: product.assetName,
  })),
  'id',
);

const importantAssets = unsortedAssets
  .filter(({ id }) => assetPriorityList.includes(id))
  .sort((asc, desc) => assetPriorityList.indexOf(asc.id) - assetPriorityList.indexOf(desc.id));

const unimportantAssets = unsortedAssets
  .filter(({ id }) => !assetPriorityList.includes(id))
  .sort((asc, desc) => asc.name.localeCompare(desc.name));

export const assets = [...importantAssets, ...unimportantAssets];

export const allAssets: Asset = {
  id: 'all-assets',
  name: 'All Assets',
};

export const assetsAndAll = [allAssets, ...assets];
