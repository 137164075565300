import { memo, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { SearchInput, Select, SelectOption } from '@cbhq/cds-web/controls';
import { Box, HStack } from '@cbhq/cds-web/layout';

import { allAssets, assets } from ':data-marketplace/data/assets';
import { allCategories, categories } from ':data-marketplace/data/categories';
import { allPackages, packages } from ':data-marketplace/data/packages';
import { getFilteredAssets } from ':data-marketplace/utils/assets';
import { getCategoryPackages } from ':data-marketplace/utils/categories';
import {
  QUERY_PARAM_ASSET_ID,
  QUERY_PARAM_CATEGORY_ID,
  QUERY_PARAM_PACKAGE_ID,
  QUERY_PARAM_PRODUCT_SEARCH,
} from ':data-marketplace/utils/constants';
import {
  EventActionType,
  EventComponentType,
  EventUUID,
  sendEvent,
} from ':data-marketplace/utils/events';

export type Props = {
  categoryId?: string | null;
  packageId?: string | null;
  itemCount: number;
};

const ProductsFilters = memo(function ProductsFilters({ categoryId, packageId, itemCount }: Props) {
  const router = useRouter();

  const categoryFilter = router.query[QUERY_PARAM_CATEGORY_ID] as string;
  const packageFilter = router.query[QUERY_PARAM_PACKAGE_ID] as string;
  const assetFilter = router.query[QUERY_PARAM_ASSET_ID] as string;
  const searchFilter = router.query[QUERY_PARAM_PRODUCT_SEARCH] as string;

  const [searchText, setSearchText] = useState(searchFilter ?? '');

  useEffect(() => {
    sendEvent(EventUUID.products_results, EventActionType.render, EventComponentType.page, {
      search_query: searchText,
      category_id: categoryFilter,
      package_id: packageFilter,
      asset_id: assetFilter,
      num_panel_results: itemCount,
    });
  }, [searchText, categoryFilter, packageFilter, assetFilter, itemCount]);

  const handleCategoryChange = useCallback(
    (newCategoryId: string) => {
      const newQuery = { ...router.query };

      // Reset dependant filters and remove the respective query parameters from the url
      delete newQuery[QUERY_PARAM_PACKAGE_ID];
      delete newQuery[QUERY_PARAM_ASSET_ID];

      // Remove empty query parameter from the url
      if (newCategoryId === allCategories.id) {
        delete newQuery[QUERY_PARAM_CATEGORY_ID];
      }

      // Update query parameter
      if (newCategoryId !== allCategories.id) {
        newQuery[QUERY_PARAM_CATEGORY_ID] = newCategoryId;
      }

      sendEvent(
        EventUUID.products_category_filter,
        EventActionType.click,
        EventComponentType.dropdown,
        {
          context: newCategoryId,
        },
      );

      // Exclusively pushing a query object to the router will update the query string for the current path
      void router.push({ query: newQuery });
    },
    [router],
  );

  const handlePackageChange = useCallback(
    (newPackageId: string) => {
      const newQuery = { ...router.query };

      // Reset dependant filters and remove the respective query parameters from the url
      delete newQuery[QUERY_PARAM_ASSET_ID];

      // Remove empty query parameter from the url
      if (newPackageId === allPackages.id) {
        delete newQuery[QUERY_PARAM_PACKAGE_ID];
      }

      // Update query parameter
      if (newPackageId !== allPackages.id) {
        newQuery[QUERY_PARAM_PACKAGE_ID] = newPackageId;
      }

      sendEvent(
        EventUUID.products_package_filter,
        EventActionType.click,
        EventComponentType.dropdown,
        {
          context: newPackageId,
        },
      );

      // Exclusively pushing a query object to the router will update the query string for the current path
      void router.push({ query: newQuery });
    },
    [router],
  );

  const handleAssetChange = useCallback(
    (newAssetId: string) => {
      const newQuery = { ...router.query };

      // Remove empty query parameter from the url
      if (newAssetId === allAssets.id) {
        delete newQuery[QUERY_PARAM_ASSET_ID];
      }

      // Update query parameter
      if (newAssetId !== allAssets.id) {
        newQuery[QUERY_PARAM_ASSET_ID] = newAssetId;
      }

      sendEvent(
        EventUUID.products_asset_filter,
        EventActionType.click,
        EventComponentType.dropdown,
        {
          context: newAssetId,
        },
      );

      // Exclusively pushing a query object to the router will update the query string for the current path
      void router.push({ query: newQuery });
    },
    [router],
  );

  const handleSearchSubmit = useCallback(
    (newSearchString: string) => {
      const newQuery = { ...router.query };

      // Remove empty query parameter from the url
      if (!newSearchString) {
        delete newQuery[QUERY_PARAM_PRODUCT_SEARCH];
      }

      // Update query parameter
      if (newSearchString) {
        newQuery[QUERY_PARAM_PRODUCT_SEARCH] = newSearchString;
      }

      sendEvent(EventUUID.products_search, EventActionType.search, EventComponentType.search_bar, {
        context: newSearchString,
      });

      // Exclusively pushing a query object to the router will update the query string for the current path
      void router.push({ query: newQuery });
    },
    [router],
  );

  // Determine the current value of the category filter.
  // A categoryId passed to this component as a prop will take priority.
  // Otherwise, it will defer to the value of the categoryFilter in the query string (if any).
  const filteredCategory = categories.find((category) => {
    const id = categoryId ?? categoryFilter;
    return id === category.id;
  });

  // Determine the current value of the package filter.
  // A packageId passed to this component as a prop will take priority.
  // Otherwise, it will defer to the value of the packageFilter in the query string (if any).
  const filteredPackage = packages.find((packageType) => {
    const id = packageId ?? packageFilter;
    return id === packageType.id;
  });

  const filteredAsset = assets.find(({ id }) => id === assetFilter);

  // Only display packages that are associated with the current filtered category.
  // If there is no filtered category, show all packages.
  const relatedPackages = filteredCategory?.id
    ? getCategoryPackages(filteredCategory.id)
    : packages;

  // Only display assets that are associated with the current filtered category and/or package.
  // If there is no filtered category and/or package, show all assets.
  const relatedAssets = getFilteredAssets(filteredCategory?.id, filteredPackage?.id);

  const categoryOptions = [allCategories, ...categories];
  const packageOptions = [allPackages, ...relatedPackages];
  const assetOptions = [allAssets, ...relatedAssets];

  return (
    <HStack gap={5} alignItems="center">
      <SearchInput
        value={searchText}
        placeholder="Search..."
        compact
        onChangeText={setSearchText}
        onSearch={handleSearchSubmit}
      />

      <HStack gap={2}>
        <Box minWidth={180}>
          <Select
            value={filteredCategory?.id}
            valueLabel={filteredCategory?.name}
            placeholder="Categories"
            compact
            disabled={Boolean(categoryId)}
            onChange={handleCategoryChange}
            testID="categorySelect"
          >
            {categoryOptions.map((categoryOption) => (
              <SelectOption
                key={categoryOption.id}
                value={categoryOption.id}
                title={categoryOption.name}
              />
            ))}
          </Select>
        </Box>

        <Box minWidth={180}>
          <Select
            value={filteredPackage?.id}
            valueLabel={filteredPackage?.name}
            placeholder="Packages"
            compact
            disabled={Boolean(packageId)}
            onChange={handlePackageChange}
            testID="packageSelect"
          >
            {packageOptions.map((packageOption) => (
              <SelectOption
                key={packageOption.id}
                value={packageOption.id}
                title={packageOption.name}
              />
            ))}
          </Select>
        </Box>

        <Box minWidth={140}>
          <Select
            value={filteredAsset?.id}
            valueLabel={filteredAsset?.name}
            placeholder="Assets"
            compact
            onChange={handleAssetChange}
            testID="assetSelect"
          >
            {assetOptions.map((assetOption) => (
              <SelectOption key={assetOption.id} value={assetOption.id} title={assetOption.name} />
            ))}
          </Select>
        </Box>
      </HStack>
    </HStack>
  );
});

export default ProductsFilters;
