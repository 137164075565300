import { IllustrationPictogramNames } from '@cbhq/cds-web';

import { packages } from ':data-marketplace/data/packages';
import { products } from ':data-marketplace/data/products';
import { Package } from ':data-marketplace/types/Package';
import { Product } from ':data-marketplace/types/Product';

export function getCategoryIcon(categoryId: string): IllustrationPictogramNames | null {
  if (categoryId === 'blockchain-data') {
    return 'tokenBaskets';
  }

  if (categoryId === 'exchange-data') {
    return 'barChart';
  }

  return null;
}

export function getCategoryProducts(categoryId: string): Product[] {
  return products.filter((product) => product.categoryId === categoryId);
}

export function getCategoryPackages(categoryId: string): Package[] {
  return packages.filter((categoryPackage) => categoryPackage.categoryId === categoryId);
}
